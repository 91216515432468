import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import * as deTexts from '../assets/i18n/de.json';
import { TranslateService } from '@ngx-translate/core';
import { ActivationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  page: string | null = null;
  id: string | null = null;
  typename: string | null = null;
  showMenu = environment.showMenu || typeof Xrm === 'undefined';

  hasActiveOverlay = false;
  constructor(
    ngxTranslate: TranslateService,
    private router: Router,
  ) {
    ngxTranslate.setTranslation('de', deTexts);
  }

  async ngOnInit() {
    // get query parameters by vanilla js
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.page = urlParams.get('data');
    this.id = urlParams.get('id');
    this.typename = urlParams.get('typename');
    console.log(
      `found nav params PAGE=${this.page} ID=${this.id} TYPENAME=${this.typename}`,
    );
    if (this.page) {
      const commands = [this.page];
      if (this.id) {
        commands.push(this.id);
      }
      await this.router.navigate(commands);
    }

    this.router.events
      .pipe(
        takeUntil(this._destroying$),
        filter((event) => event instanceof ActivationEnd),
      )
      .subscribe(() => {
        this.hasActiveOverlay = this.router.routerState.root.children.length > 1;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
