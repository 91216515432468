{
  "PAYMENT_PERIOD": {
    "MONTHLY": "Monatlich",
    "QUARTERLY": "Quartalsweise",
    "HALFYEARLY": "Halbjährlich",
    "YEARLY": "Jährlich"
  },
  "OFFSET_UNIT": {
    "DAYS": "Tage",
    "WEEKS": "Wochen",
    "MONTHS": "Monate"
  },
  "OFFSET_RELATIVE_TO": {
    "END": "Periodenende",
    "START": "Periodenanfang"
  },
  "VALIDATORS": {
    "required": "Dieses Feld wird benötigt.",
    "minNumber": "Der Wert muss mindestens #{refValues[1]} sein.",
    "maxNumber": "Der Wert darf höchstens #{refValues[1]} sein.",
    "greaterThan": "Der Wert muss grösser als #{refValues[1]} sein.",
    "minLength": "Der Wert muss mindestens eine Länge von #{refValues[1]} haben.",
    "matDatepickerParse": "Ungültiges Datum",
    "invalidOrderOfDates": "Startdatum muss vor dem Enddatum liegen.",
    "projectStartBeforeEnd": "Auftragsstart muss vor dem Ende liegen",
    "projectEndAfterStart": "Auftragsende muss nach dem Start liegen",
    "fromLessThanTo": "Von muss kleiner als Bis sein."
  },
  "AG_GRID": {
    "andCondition": "UND",
    "applyFilter": "anwenden",
    "area": "Bereich",
    "areaChart": "Bereich",
    "areaGroup": "Bereich",
    "ariaChecked": "geprüft",
    "ariaColumn": "Spalte",
    "ariaColumnGroupToggleVisibility": "Sichtbarkeit der Spaltengruppe umschalten",
    "ariaColumnSelectAll": "Alle Spalten auswählen umschalten",
    "ariaColumnToggleVisibility": "Spalte Sichtbarkeit umschalten",
    "ariaPivotDropZonePanelLabel": "Spaltenbezeichnungen",
    "ariaRowGroupDropZonePanelLabel": "Zeilengruppen",
    "ariaSortableColumn": "Eingabe um Spalten zu sortieren",
    "ariaDateFilterInput": "Datumsfilter Eingabe",
    "ariaFilterColumnsInput": "Filterspalten Eingabe",
    "ariaFilterFromValue": "Filter von Wert",
    "ariaFilterInput": "Filtereingang",
    "ariaFilterToValue": "Nach Wert filtern",
    "ariaFilterValue": "Filterwert",
    "ariaFilteringOperator": "Filterfunktion",
    "ariaHidden": "verdeckt",
    "ariaIndeterminate": "unbestimmt",
    "ariaInputEditor": "Eingabe Editor",
    "ariaRowDeselect": "Drücken Sie die Leertaste, um die Auswahl dieser Zeile aufzuheben.",
    "ariaRowSelect": "Drücken Sie die Leertaste, um diese Zeile auszuwählen.",
    "ariaRowSelectAll": "Drücken Sie die Leertaste, um die Auswahl aller Zeilen umzuschalten.",
    "ariaRowToggleSelection": "Drücken Sie die Leertaste, um die Zeilenauswahl umzuschalten.",
    "ariaSearch": "suchen",
    "ariaSearchFilterValues": "Filterwerte suchen",
    "ariaUnchecked": "ungeprüft",
    "ariaVisible": "sichtbar",
    "automatic": "automatisch",
    "autosizeAllColumns": "Alle Spalten automatisch vergrößern",
    "autosizeThiscolumn": "Diese Spalte automatisch vergrößern",
    "avg": "Durchschnitt",
    "axis": "Achse",
    "background": "Hintergrund",
    "barChart": "Balken",
    "barGroup": "Balken",
    "blanks": "(Leerzeichen)",
    "blur": "unscharf",
    "bold": "fett",
    "boldItalic": "fett kursiv",
    "bottom": "unten",
    "bubble": "Blase",
    "bubbleTooltip": "Blase",
    "callout": "Aufruf",
    "cancelFilter": "stornieren",
    "categories": "Kategorien",
    "category": "Kategorie",
    "chart": "Tabelle",
    "chartDownloadToolbarTooltip": "Tabelle herunterladen",
    "chartLinkToolbarTooltip": "mit dem Netz verbunden",
    "chartRange": "Tabellenbereich",
    "chartSettingsToolbarTooltip": "Menü",
    "chartUnlinkToolbarTooltip": "vom Netz getrennt",
    "clearFilter": "löschen",
    "collapseAll": "alles schließen",
    "color": "Farbe",
    "columnChart": "Spalte",
    "columnGroup": "Spalte",
    "columns": "Spalten",
    "contains": "enthält",
    "copy": "kopieren",
    "copyWithGroupHeaders": "kopieren mit Gruppenüberschriften",
    "copyWithHeaders": "kopieren mit Überschriften",
    "count": "zählen",
    "csvExport": "CSV Export",
    "ctrlC": "STRG+C",
    "ctrlV": "STRG+V",
    "cut": "ausschneiden",
    "data": "Daten",
    "dateFilter": "Datumsfilter",
    "dateFormatOoo": "jjjj-mm-tt",
    "defaultCategory": "(keine)",
    "doughnut": "Artikel",
    "doughnutTooltip": "Artikel",
    "empty": "Wählen Sie eine",
    "enabled": "aktiviert",
    "endsWith": "endet mit",
    "equals": "ist gleich",
    "excelExport": "Excel Export (.xlsx)",
    "excelXmlExport": "Excel Export (.xml)",
    "expandAll": "alle erweitern",
    "export": "Export",
    "fillOpacity": "Deckkraft der Füllung",
    "filterOoo": "Filter...",
    "filteredRows": "gefiltert",
    "filters": "Filter",
    "firstPage": "erste Seite",
    "font": "Font",
    "format": "Format",
    "greaterThan": "größer als",
    "greaterThanOrEqual": "größer als oder gleich",
    "group": "Gruppe",
    "groupBy": "gruppieren nach",
    "groupedAreaTooltip": "Fläche",
    "groupedBar": "gruppiert",
    "groupedBarTooltip": "gruppiert",
    "groupedColumn": "gruppiert",
    "groupedColumnTooltip": "gruppiert",
    "groups": "Zeilengruppe",
    "height": "Höhe",
    "histogramBinCount": "Anzahl der Plätze",
    "histogramChart": "Histogram",
    "histogramGroup": "Histogram",
    "histogramTooltip": "Histogram",
    "inRange": "im Angebot",
    "inRangeEnd": "von",
    "inRangeStart": "bis",
    "italic": "kursiv",
    "itemPaddingX": "Artikelauffüllung X",
    "itemPaddingY": "Artikelauffüllung Y",
    "itemSpacing": "Artikelabstand",
    "labels": "Etiketten",
    "lastPage": "letzte Seite",
    "layoutHorizontalSpacing": "horizontale Abstände",
    "layoutVerticalSpacing": "vertikale Abstände",
    "left": "links",
    "legend": "Legende",
    "length": "Länge",
    "lessThan": "weniger als",
    "lessThanOrEqual": "weniger als oder gleich",
    "line": "Zeile",
    "lineGroup": "Zeile",
    "lineTooltip": "Zeile",
    "lineWidth": "Zeilenbreite",
    "loadingOoo": "lädt...",
    "markerPadding": "Füllzeichen für die Markierung",
    "markerSize": "Größe der Markierung",
    "markerStroke": "Markierungsstrich",
    "markers": "Markierung",
    "max": "Max",
    "maxSize": "maximale Größe",
    "min": "Min",
    "minSize": "minimale Grüße",
    "more": "mehr",
    "navigator": "Navigator",
    "nextPage": "nächste Seite",
    "noDataToChart": "Es sind keine Daten verfügbar, die kartiert werden können.",
    "noMatches": "keine Treffer",
    "noPin": "kein Pin",
    "noRowsToShow": "Keine Zeilen anzuzeigen",
    "none": "keine",
    "normal": "Normal",
    "normalizedArea": "100% gestapelt",
    "normalizedAreaTooltip": "100% gestapelt",
    "normalizedBar": "100% gestapelt",
    "normalizedBarTooltip": "100% gestapelt",
    "normalizedColumn": "100% gestapelt",
    "normalizedColumnTooltip": "100% gestapelt",
    "notContains": "nicht enthalten",
    "notEqual": "nicht gleich",
    "number": "Nummer",
    "numberFilter": "Nummernfilter",
    "of": "aus",
    "offset": "Versatz",
    "offsets": "Versatz",
    "orCondition": "oder",
    "padding": "Füllung",
    "page": "Seite",
    "paired": "gekoppelter Modus",
    "paste": "einfügen",
    "pie": "Pie",
    "pieChart": "Pie",
    "pieGroup": "Pie",
    "pieTooltip": "Pie",
    "pinColumn": "Pin Spalte",
    "pinLeft": "Pin links",
    "pinRight": "Pin rechts",
    "pivotChart": "Pivot Tabelle",
    "pivotChartAndPivotMode": "Pivot Tabelle & Pivot Modus",
    "pivotChartRequiresPivotMode": "Pivottabelle erfordert aktivierten Pivot-Modus.",
    "pivotChartTitle": "Pivot Tabelle",
    "pivotColumnsEmptyMessage": "Hierher ziehen, um Spaltenbeschriftungen festzulegen",
    "pivotMode": "Pivot Modus",
    "pivots": "Spaltenüberschrift",
    "position": "Position",
    "predefined": "vorausgewählt",
    "previousPage": "vorherige Seite",
    "rangeChartTitle": "Bereichstabelle",
    "resetColumns": "Spalten zurücksetzen",
    "resetFilter": "zurücksetzen",
    "right": "rechts",
    "rowGroupColumnsEmptyMessage": "Hierher ziehen, um Zeilengruppen festzulegen",
    "scatter": "streuen",
    "scatterGroup": "X Y (streuen)",
    "scatterTooltip": "streuen",
    "searchOoo": "suche...",
    "selectAll": "(alles auswählen)",
    "selectAllSearchResults": "(Alle Suchergebnisse auswählen)",
    "selectedRows": "ausgewählte",
    "series": "Reihe",
    "setFilter": "Filter einstellen",
    "settings": "Einstellungen",
    "shadow": "Schatten",
    "size": "Größe",
    "spacing": "Abstand",
    "stackedArea": "gestapelt",
    "stackedAreaTooltip": "gestapelt",
    "stackedBar": "gestapelt",
    "stackedBarTooltip": "gestapelt",
    "stackedColumn": "gestapelt",
    "stackedColumnTooltip": "gestapelt",
    "startsWith": "beginnt mit",
    "strokeOpacity": "Linientransparenz",
    "strokeWidth": "Strichbreite",
    "sum": "Summe",
    "textFilter": "Text Filter",
    "thickness": "Dicke",
    "ticks": "Häkchen",
    "time": "Zeit",
    "title": "Titel",
    "titlePlaceholder": "Diagrammtitel - Doppelklick zum Bearbeiten",
    "to": "nach",
    "tooltips": "nützliche Hinweise",
    "top": "Anfang",
    "totalAndFilteredRows": "Zeilen",
    "totalRows": "Zeilen gesamt",
    "ungroupBy": "Auflösen der Gruppe durch",
    "valueAggregation": "Wertermittlung (Aggregation)",
    "valueColumnsEmptyMessage": "Hierher ziehen, um zu summieren.",
    "values": "Werte",
    "width": "Weite",
    "xOffset": "X Versatz",
    "xRotation": "X Drehung",
    "xType": "X Typ",
    "xyChart": "X Y (Streuung)",
    "xyValues": "X Y Werte",
    "yOffset": "Y Versatz",
    "yRotation": "Y Drehung"
  }
}
