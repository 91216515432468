import { Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'app-overlay',
  standalone: true,
  imports: [RouterOutlet, MatIcon, MatIconButton],
  templateUrl: './overlay.component.html',
  styleUrl: './overlay.component.scss',
})
export class OverlayComponent {
  private router = inject(Router);

  async close() {
    await this.router.navigate([{ outlets: { overlay: null } }]);
  }
}
