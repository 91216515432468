import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverlayComponent } from './overlay/overlay.component';

const routes: Routes = [
  {
    path: 'meters',
    loadComponent: () =>
      import('./meter-list/meter-list.component').then((m) => m.MeterListComponent),
  },
  {
    path: 'meters/:id',
    loadComponent: () => import('./meter/meter.component').then((m) => m.MeterComponent),
  },
  {
    loadComponent: () =>
      import('./contract-position/contract-position.component').then(
        (m) => m.ContractPositionComponent,
      ),
    path: 'contract-position/:id',
  },
  {
    loadComponent: () =>
      import('./contract-list/contract-list.component').then(
        (m) => m.ContractListComponent,
      ),
    path: 'contracts',
  },
  {
    loadComponent: () =>
      import('./contract-details/contract-details.component').then(
        (m) => m.ContractDetailsComponent,
      ),
    path: 'contracts/:id',
  },
  {
    path: 'yield-controls',
    loadComponent: () =>
      import('./yield-control-list/yield-control-list.component').then(
        (m) => m.YieldControlListComponent,
      ),
  },
  {
    path: 'yield-control',
    loadChildren: () =>
      import('./yield-control/yield-control-routes').then((m) => m.YieldControlModule),
  },
  {
    path: 'yield-control/:id',
    loadChildren: () =>
      import('./yield-control/yield-control-routes').then((m) => m.YieldControlModule),
  },
  {
    path: 'tbf-values/:id',
    loadComponent: () =>
      import('./tbf-values/tbf-values.component').then((m) => m.TbfValuesComponent),
  },
  {
    path: 'tbf-values',
    loadComponent: () =>
      import('./tbf-values/tbf-values.component').then((m) => m.TbfValuesComponent),
  },
  {
    path: 'cases/:id',
    loadComponent: () =>
      import('./case-management/edit-case/edit-case.component').then(
        (m) => m.EditCaseComponent,
      ),
  },
  {
    path: 'overlay',
    outlet: 'overlay',
    component: OverlayComponent,
    children: [
      {
        path: 'yield-control-config/:meterId',
        loadComponent: () =>
          import(
            './yield-control/yield-control-group-config/yield-control-group-config.component'
          ).then((m) => m.YieldControlGroupConfigComponent),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
